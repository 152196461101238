import React from "react";
import Layout from "../component/layout";
import HomeImage from "../images/java-dev-home-image.png";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import ItProsConsComponent from "../component/Services/IT/ItProsConsComponent";
import Icon from "../images/card-icon.png";
import CardsComponent from "../component/CardsComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import AccordionComponent from "../component/accordionComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import FooterComponent from "../component/footerComponent";

const serviceJavaDev = () => {
  const seo = {
    title: "Outsource Java Development Services | Motomtech",
    metaDesc:
      "Expert outsource Java development teams available to build your next project, or augment your current development team.",
  };

  const arr1 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Custom Java Development:
        </p>
      ),
      description:
        "At Motomtech, we offer bespoke Java development services tailored to your unique business requirements. Our team of seasoned developers is adept at creating robust, scalable, and efficient Java applications that elevate your business operations and facilitate growth.",
      number: "1",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Java IT Augmentation Services:
        </p>
      ),
      description:
        "If your in-house team needs an extra set of skilled hands, our Java IT augmentation services are your solution. We provide proficient Java developers who seamlessly integrate with your existing team, contributing their expertise and experience to accelerate project timelines and enhance quality.",
      number: "2",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Java QA Services:
        </p>
      ),
      description:
        "Quality is at the heart of everything we do at Motomtech. Our Java QA services involve meticulous testing procedures to ensure your application's flawless performance. From unit testing to integration and system testing, we use the latest tools and practices to guarantee a bug-free, top-quality product.",
      number: "3",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Java Maintenance:
        </p>
      ),
      description:
        "Our services do not end after development. We provide comprehensive Java maintenance services to ensure your application continues to operate optimally and remains updated with the latest technological advancements. Our proactive approach helps minimize downtime and maximizes application longevity.",
      number: "4",
    },
  ];
  const arr2 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Java Consulting:
        </p>
      ),
      description:
        "Leveraging our vast experience and deep domain knowledge, we offer Java consulting services to help businesses make informed decisions. Whether you're contemplating a new Java project or need expert advice to enhance an existing one, our consultants provide strategic insights that align with your business objectives and technology roadmap.",
      number: "5",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Java Integration Services:
        </p>
      ),
      description:
        "If you're looking to migrate your existing applications to Java, we have you covered. Our Java migration services are designed to make the transition smooth, risk-free, and efficient, ensuring your migrated applications retain their functionality and performance while gaining the benefits of Java's robustness and scalability.",
      number: "6",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Java Migration Services:
        </p>
      ),
      description:
        "If you're looking to migrate your existing applications to Java, we have you covered. Our Java migration services are designed to make the transition smooth, risk-free, and efficient, ensuring your migrated applications retain their functionality and performance while gaining the benefits of Java's robustness and scalability.",
      number: "7",
    },
  ];

  const cards = [
    {
      icon: Icon,
      title: "Java for Big Data Technologies: ",
      description:
        "Leveraging Java, we help businesses navigate the complexities of Big Data, crafting robust and efficient solutions that enable you to manage, analyze, and leverage large data sets, ultimately unlocking valuable insights and improving decision-making.",
    },
    {
      icon: Icon,
      title: "Trading Applications with Java:",
      description:
        "Our team excels at developing high-performing, secure, and reliable trading applications using Java. We ensure these platforms are capable of processing high volumes of data in real-time, providing users with accurate and up-to-date information.",
    },
    {
      icon: Icon,
      title: "Embedded Systems with Java:",
      description:
        "We harness the power of Java to develop advanced embedded systems that drive innovation in various industries. These systems are scalable, reliable, and capable of functioning efficiently in constrained environments.",
    },
    {
      icon: Icon,
      title: "Java Applets:",
      description:
        "Our team can create dynamic and interactive Java applets that enhance your website's functionality and user experience. These applets allow for real-time interaction without the need for a server connection.",
    },
    {
      icon: Icon,
      title: "Java Distributed Applications:",
      description:
        "We specialize in building Java distributed applications that allow for efficient data processing and resource sharing across multiple systems, improving performance and scalability while maintaining security.",
    },
    {
      icon: Icon,
      title: "Java-Based CRM:",
      description:
        "We offer tailored Java-based CRM solutions that streamline your customer management processes, enhance customer relationships, and boost your sales and marketing efforts.",
    },
    {
      icon: Icon,
      title: "ERP with Java:",
      description:
        "At Motomtech, we develop powerful, customizable Java-based ERP solutions that seamlessly integrate various business processes, facilitating better resource management, improved efficiency, and data-driven decision-making.",
    },
    {
      icon: Icon,
      title: "Java Cloud Applications:",
      description:
        "By combining the capabilities of Java and the flexibility of cloud computing, we develop high-performing, scalable, and cost-effective cloud applications. These applications support business agility, allowing for easy scaling as your business needs change.",
    },
  ];

  const questions1 = [
    {
      title: "What kind of applications can you build using Java?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Java's versatility and robustness make it suitable for a wide range
            of applications. It's commonly used in the development of
            enterprise-level applications due to its ability to handle
            complexity and scalability. This includes applications like Customer
            Relationship Management (CRM) systems, Enterprise Resource Planning
            (ERP) systems, and other web-based applications.
          </p>
          <p className="accordion-section__card-desc">
            Java's platform independence also makes it a great choice for
            building cross-platform desktop applications. Furthermore, Java is
            also extensively used in the development of Android applications,
            with Google recommending Java as one of the primary languages for
            Android app development. Its 'Write Once, Run Anywhere' approach
            allows these applications to run on various devices, increasing
            their reach and usability. Lastly, Java also finds use in embedded
            systems, big data technologies, trading applications, and more.
          </p>
        </>
      ),
      eventKey: 1,
    },
    {
      title: "What makes Java a high-level language?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            A high-level language in programming is one that is designed to be
            easily understood by humans. Java qualifies as a high-level language
            because of its English-like syntax and abstraction from the details
            of the computer system. It shields the developer from complex
            details of the computer, such as memory management, which is handled
            automatically by Java.
          </p>
          <p className="accordion-section__card-desc">
            Java also provides a rich set of APIs and a powerful suite of tools,
            making it easier for developers to create complex applications
            without having to write everything from scratch. Moreover, Java
            incorporates advanced programming paradigms like Object-Oriented
            Programming (OOP), which allows for the creation of modular programs
            and reusable code, further simplifying the development process.
          </p>
        </>
      ),
      eventKey: 2,
    },
  ];

  const questions2 = [
    {
      title: "What are the pros and cons of using Java for development?",
      description: (
        <>
          <p className="accordion-section__card-desc">
            Java has numerous advantages. Its 'Write Once, Run Anywhere'
            philosophy ensures platform independence, allowing Java applications
            to run on any device that has a Java Runtime Environment (JRE). Java
            is also known for its robustness, with built-in features for memory
            management and error handling that increase the reliability of
            software built using it. Additionally, it has a rich set of
            libraries and tools, a large and active developer community, and
            strong support for enterprise-level development.
          </p>
          <p className="accordion-section__card-desc">
            However, Java also has a few disadvantages. While it's easier to
            learn compared to some other languages, newcomers might find certain
            concepts, like its Object-Oriented Programming paradigm, challenging
            initially. Java's memory management and automatic garbage collection
            can lead to performance issues in time-sensitive applications. Also,
            its verbosity can lead to longer code lines compared to languages
            like Python, which can affect code readability. However, with a good
            understanding of the language and best practices, many of these cons
            can be effectively mitigated.
          </p>
        </>
      ),
      eventKey: 3,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={"Outsource Java Development Services"}
        homeDescription={
          "Complete your next java project faster and at a lower cost with Motomtech’s Java development outsourcing services for enterprises and startups alike."
        }
        homeImage={HomeImage}
        homeButton={"Request our services"}
        isGatsby={false}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={
          "Outsource your Java Needs With Fully Customizable Java Development Services"
        }
        description1={
          "Choosing Motomtech for your Java development needs is not just a decision, but an investment in quality, expertise, and efficiency. We pride ourselves on our ability to deliver the best Java solutions, all the while helping you maintain focus on your core business operations. As a leading service provider in the IT sector, outsourcing Java development services to Motomtech ensures a stress-free, efficient, and cost-effective path to achieving your software goals."
        }
        description2={
          "Our experience in Java development stretches over a considerable timeline and covers a broad spectrum of projects and business environments. We've developed robust applications for businesses in industries ranging from finance to healthcare, from e-commerce to real estate. This wide-reaching experience not only enables us to quickly understand and adapt to your unique business requirements but also to foresee potential challenges, ensuring that the solutions we deliver are not just adequate, but exceptional."
        }
        description3={
          "At Motomtech, we take a personalized approach to each project, leveraging our technical prowess and innovative thinking to create software that not only meets but exceeds expectations. Our dedicated team of Java developers will work closely with you from inception to execution, ensuring your vision is realized while adhering to high-quality standards and timelines. Our team's versatility with different Java frameworks and tools makes it possible to cater to a wide array of development needs, ranging from building complex enterprise applications to simple and efficient web applications."
        }
        description4={
          "Our commitment to excellence is not limited to the technical realm. We believe in forging long-lasting relationships based on trust and transparency. As our partner, you will have constant access to project updates, keeping you abreast of every milestone we reach together. At Motomtech, we understand that outsourcing Java development services involves a leap of faith, but we assure you it is a leap that brings a rewarding journey and successful outcomes. Our track record speaks volumes about our reliability and trustworthiness, making us the right choice for your Java development needs."
        }
        description5={
          "When you choose Motomtech, you're not just outsourcing your Java development; you're gaining a partner who is as invested in your project's success as you are. Let our experience pave the way for your success. Trust your Java development needs with Motomtech and reap the benefits of world-class expertise and unparalleled dedication."
        }
      />
      <ItProsConsComponent
        title={"Java Development Services"}
        arr1={arr1}
        arr2={arr2}
      />
      <ItExpandTeamComponent
        title={"Why Leverage Java For Your Next Project?"}
        description1={
          "Java is one of the most widely-used programming languages in the world, trusted by developers for its robustness, versatility, and adaptability. Choosing Java for your next project opens up a world of possibilities and benefits, many of which stand head and shoulders above those offered by other programming languages."
        }
        description2={
          "Firstly, Java's platform independence makes it an excellent choice for a diverse range of applications. Its 'Write Once, Run Anywhere' (WORA) philosophy allows for the development of software that can run consistently across multiple platforms, whether it's Windows, Mac, Linux, or any other operating system. This versatility not only broadens your software's reach but also simplifies the development process by eliminating the need for platform-specific adjustments."
        }
        description3={
          "Java also champions security, with built-in features designed to minimize the impact of errors and prevent malicious activities. Its secure runtime environment, automatic memory management, and strong typing all contribute to the robustness of Java applications, reducing the risk of security breaches and system crashes."
        }
        description4={
          "One of Java's key advantages is its simplicity and readability. Despite its powerful capabilities, Java has a comparatively gentle learning curve, with a syntax that is clean, easy to grasp, and similar to English. Moreover, Java is fully object-oriented, meaning it allows for cleaner code organization, easier maintenance, and reuse of code, leading to a more efficient and economical development process."
        }
        description5={
          "In terms of scalability and performance, Java is a stalwart. It can handle large-scale, complex applications with ease, making it a favorite choice for enterprise-level development. Its multithreading capability allows concurrent execution of two or more parts of a program for maximum CPU utilization, resulting in improved performance."
        }
        description6={
          "Lastly, the robust ecosystem that surrounds Java - including a vast array of libraries, frameworks, and tools, along with an active global community - can greatly expedite and enhance the development process. Leveraging Java for your next project means you tap into a rich vein of resources and a wealth of collective knowledge that can help you overcome any development challenge and deliver a truly exceptional product."
        }
      />
      <CardsComponent
        title={"Outsourced Java Development Solutions"}
        description={
          "At Motomtech, we are committed to transforming your business vision into reality by delivering results-oriented Java solutions. We leverage our vast expertise and innovative approaches to create not just applications, but strategic tools that unlock significant benefits for your business. Our broad portfolio of Java development services is designed to cater to a diverse array of domains and requirements, enabling us to offer solutions that perfectly align with your objectives and operational environment."
        }
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <TechStackComponent />
      </div>
      <CustomDevelopmentVideoComponent />
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={"Frequently asked questions"}
        subtitle={"Here you can find some questions that our user ask te most."}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={"Jumpstart your startup with Motomtech"}
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default serviceJavaDev;
